<template>
  <v-container fluid class="grey lighten-2">
    <v-row>
      <v-col class="mt-2" cols="12">
        <v-dialog v-model="dialog" max-width="800" scrollable persistent transition="dialog-bottom-transition">
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on">
              <v-icon id="media">{{ icon }}</v-icon>{{ phrase.title_media }}
            </v-btn>
          </template>

          <v-card height=400>
            <v-card-title class="text-h5 grey lighten-2">{{ phrase.title_media }}</v-card-title>
            <v-card-text>
              <div class="my-5 text--primary">{{ phrase.help_media }}</div>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="dialog = false">
                {{ phrase.dialog_close }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
      <v-col>
        <v-row>
          <v-col class="mt-2" cols="6" sm="6" md="3" lg="3">
            <v-card>
              <v-card-title>新聞</v-card-title>
              <v-layout justify-center>
                <v-img max-height="200" max-width="200" v-bind:src="require('@/assets/media/media_newspaper.jpg')"></v-img>
              </v-layout>
              <v-select small-chips multiple class="d-inline-flex align-center mt-2 ml-4" v-model="$store.state.select.media.newspaper" :items=this.$store.state.login_response.media_newspaper item-text="select" item-value="cost" return-object label="選択してください" @change="value_media_newspaper"></v-select>
              <v-card-subtitle>小計{{ this.$store.state.breakdown.media_newspaper.toLocaleString() }}円</v-card-subtitle>
            </v-card>
          </v-col>
          <v-col class="mt-2" cols="6" sm="6" md="3" lg="3">
            <v-card>
              <v-card-title>テレビ・ラジオ</v-card-title>
              <v-layout justify-center>
                <v-img max-height="200" max-width="200" v-bind:src="require('@/assets/media/media_tv_radio.jpg')"></v-img>
              </v-layout>
              <v-select small-chips multiple class="d-inline-flex align-center mt-2 ml-4" v-model="$store.state.select.media.tv_radio" :items=this.$store.state.login_response.media_tv_radio item-text="select" item-value="cost" return-object label="選択してください" @change="value_media_tv_radio"></v-select>
              <v-card-subtitle>小計{{ this.$store.state.breakdown.media_tv_radio.toLocaleString() }}円</v-card-subtitle>
            </v-card>
          </v-col>
          <v-col class="mt-2" cols="6" sm="6" md="3" lg="3">
            <v-card>
              <v-card-title>図書</v-card-title>
              <v-layout justify-center>
                <v-img max-height="200" max-width="200" v-bind:src="require('@/assets/media/media_books.jpg')"></v-img>
              </v-layout>
              <v-select small-chips multiple class="d-inline-flex align-center mt-2 ml-4" v-model="$store.state.select.media.books" :items=this.$store.state.login_response.media_books item-text="select" item-value="cost" return-object label="選択してください" @change="value_media_books"></v-select>
              <v-card-subtitle>小計{{ this.$store.state.breakdown.media_books.toLocaleString() }}円</v-card-subtitle>
            </v-card>
          </v-col>
          <v-col class="mt-2" cols="6" sm="6" md="3" lg="3">
            <v-card>
              <v-card-title>WEBサービス</v-card-title>
              <v-layout justify-center>
                <v-img max-height="200" max-width="200" v-bind:src="require('@/assets/media/media_web.jpg')"></v-img>
              </v-layout>
              <v-select small-chips multiple class="d-inline-flex align-center mt-2 ml-4" v-model="$store.state.select.media.web" :items=this.$store.state.login_response.media_web item-text="select" item-value="cost" return-object label="選択してください" @change="value_media_web"></v-select>
              <v-card-subtitle>小計{{ this.$store.state.breakdown.media_web.toLocaleString() }}円</v-card-subtitle>
            </v-card>
          </v-col>
          <v-col class="mt-2" cols="6" sm="6" md="3" lg="3">
            <v-card>
              <v-card-title>インターネット接続</v-card-title>
              <v-layout justify-center>
                <v-img max-height="200" max-width="200" v-bind:src="require('@/assets/media/media_isp.jpg')"></v-img>
              </v-layout>
              <v-select small-chips multiple class="d-inline-flex align-center mt-2 ml-4" v-model="$store.state.select.media.isp" :items=this.$store.state.login_response.media_isp item-text="select" item-value="cost" return-object label="選択してください" @change="value_media_isp"></v-select>
              <v-card-subtitle>小計{{ this.$store.state.breakdown.media_isp.toLocaleString() }}円</v-card-subtitle>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="mt-2" cols="6" sm="6" md="3" lg="3">
            <v-card>
              <v-card-title>合計支出金額</v-card-title>
              <v-card-title>{{ (this.$store.state.breakdown.media_newspaper + this.$store.state.breakdown.media_tv_radio + this.$store.state.breakdown.media_books + this.$store.state.breakdown.media_web + this.$store.state.breakdown.media_isp).toLocaleString() }}円</v-card-title>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import words from '../assets/words.json'

export default({
  data: () => ({
    icon: 'mdi-newspaper-variant-outline',
    dialog: false,
    phrase: {}
  }),
  mounted: function () {
    this.getWords()
  },
  methods: {
    value_media_newspaper: function(value) {
      this.$store.commit('value_media_newspaper', value);
      this.$store.commit('rebalance');
      this.$store.commit('set_progress');
    },
    value_media_tv_radio: function(value) {
      this.$store.commit('value_media_tv_radio', value);
      this.$store.commit('rebalance');
      this.$store.commit('set_progress');
    },
    value_media_books: function(value) {
      this.$store.commit('value_media_books', value);
      this.$store.commit('rebalance');
      this.$store.commit('set_progress');
    },
    value_media_web: function(value) {
      this.$store.commit('value_media_web', value);
      this.$store.commit('rebalance');
      this.$store.commit('set_progress');
    },
    value_media_isp: function(value) {
      this.$store.commit('value_media_isp', value);
      this.$store.commit('rebalance');
      this.$store.commit('set_progress');
    },
    getWords: function () {
      this.phrase = words
    }
  }
})
</script>
