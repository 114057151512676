<template>
  <v-container fluid class="grey lighten-2">
    <v-row>
      <v-col class="mt-2" cols="12">
        <v-dialog v-model="dialog" max-width="800" scrollable persistent transition="dialog-bottom-transition">
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on">
              <v-icon id="fashion">{{ icon }}</v-icon>{{ phrase.title_fashion }}
            </v-btn>
          </template>

          <v-card height=400>
            <v-card-title class="text-h5 grey lighten-2">{{ phrase.title_fashion }}</v-card-title>
            <v-card-text>
              <div class="my-5 text--primary">{{ phrase.help_fashion }}</div>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="dialog = false">
                {{ phrase.dialog_close }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
      <v-col>
        <v-sheet
          color="grey lighten-2"
          elevation="10"
        >
            <v-col>メンズ</v-col>
            <v-col>
              <v-row>
                <v-col class="mt-2" cols="4" sm="4" md="2" lg="2" v-for="(men, index) in this.$store.state.login_response.fashion_men" :key="index">
                  <v-card>
                    <v-card-title>{{ men.item }}</v-card-title>
                    <v-card-subtitle>単価{{ men.cost.toLocaleString() }}円</v-card-subtitle>
                    <v-col align="center">
                      <v-img max-height="200" max-width="200" v-bind:src="require('@/assets/fashion/'+men.img+'.jpg')"></v-img>
                    </v-col>
                    <v-card-text>
                      <v-col>
                        <v-row class="justify-space-around">
                          <v-select :items=array_mens return-object :label="$store.state.select.fashion_men[index]" dense @change="value_fashion_men(index, $event)"></v-select>
                        </v-row>
                      </v-col>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
            <v-col>小計{{ this.$store.state.breakdown.fashion_men.reduce((sum, i) => { return sum + i.order; }, 0).toLocaleString() }}円</v-col>
            <v-col>ウィメンズ</v-col>
            <v-col>
              <v-row>
                <v-col class="mt-2" cols="4" sm="4" md="2" lg="2" v-for="(women, index) in this.$store.state.login_response.fashion_women" :key="women.key">
                  <v-card>
                    <v-card-title>{{ women.item }}</v-card-title>
                    <v-card-subtitle>単価{{ women.cost.toLocaleString() }}円</v-card-subtitle>
                    <v-col align="center">
                      <v-img max-height="200" max-width="200" v-bind:src="require('@/assets/fashion/'+women.img+'.jpg')"></v-img>
                    </v-col>
                    <v-card-text>
                      <v-col>
                        <v-row class="justify-space-around">
                          <v-select :items=array_womens return-object :label="$store.state.select.fashion_women[index]" dense @change="value_fashion_women(index, $event)"></v-select>
                        </v-row>
                      </v-col>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
            <v-col>小計{{ this.$store.state.breakdown.fashion_women.reduce((sum, i) => { return sum + i.order; }, 0).toLocaleString() }}円</v-col>
          <div v-if="this.$store.state.login_response.fashion_kids">
            <v-col>キッズ</v-col>
            <v-col>
              <v-row>
                <v-col class="mt-2" sidecols="4" sm="4" md="2" lg="2" v-for="(kids, index) in this.$store.state.login_response.fashion_kids" :key="kids.key">
                  <v-card>
                    <v-card-title>{{ kids.item }}</v-card-title>
                    <v-card-subtitle>単価{{ kids.cost.toLocaleString() }}円</v-card-subtitle>
                    <v-col align="center">
                      <v-img max-height="200" max-width="200" v-bind:src="require('@/assets/fashion/'+kids.img+'.jpg')"></v-img>
                    </v-col>
                    <v-card-text>
                      <v-col>
                        <v-row class="justify-space-around">
                          <v-select :items=array_kids return-object :label="$store.state.select.fashion_kids[index]" dense @change="value_fashion_kids(index, $event)"></v-select>
                        </v-row>
                      </v-col>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
            <v-col>小計{{ this.$store.state.breakdown.fashion_kids.reduce((sum, i) => { return sum + i.order; }, 0).toLocaleString() }}円</v-col>
          </div>
          <div v-if="this.$store.state.login_response.fashion_baby">
            <v-col>ベビー</v-col>
            <v-col>
              <v-row>
                <v-col class="mt-2" sidecols="4" sm="4" md="2" lg="2" v-for="(baby, index) in this.$store.state.login_response.fashion_baby" :key="baby.key">
                  <v-card>
                    <v-card-title>{{ baby.item }}</v-card-title>
                    <v-card-subtitle>単価{{ baby.cost.toLocaleString() }}円</v-card-subtitle>
                    <v-col align="center">
                      <v-img max-height="200" max-width="200" v-bind:src="require('@/assets/fashion/'+baby.img+'.jpg')"></v-img>
                    </v-col>
                    <v-card-text>
                      <v-col>
                        <v-row class="justify-space-around">
                          <v-select :items=array_baby return-object :label="$store.state.select.fashion_baby[index]" dense @change="value_fashion_baby(index, $event)"></v-select>
                        </v-row>
                      </v-col>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
            <v-col>小計{{ this.$store.state.breakdown.fashion_baby.reduce((sum, i) => { return sum + i.order; }, 0).toLocaleString() }}円</v-col>
          </div>
          <v-col class="mt-2" cols="4" sm="4" md="2" lg="2">
            <v-card min-height="100%" min-width="80%">
              <v-card-title>合計支出金額</v-card-title>
              <v-card-title>{{ (
                  this.$store.state.breakdown.fashion_men.reduce((sum, i) => { return sum + i.order; }, 0) +
                  this.$store.state.breakdown.fashion_women.reduce((sum, i) => { return sum + i.order; }, 0) +
                  this.$store.state.breakdown.fashion_kids.reduce((sum, i) => { return sum + i.order; }, 0) +
                  this.$store.state.breakdown.fashion_baby.reduce((sum, i) => { return sum + i.order; }, 0)
                ).toLocaleString() }}円
              </v-card-title>
            </v-card>
          </v-col>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import words from '../assets/words.json'

export default({
  data: () => ({
    icon: 'mdi-hanger',
    available_mens: 1,
    available_womens: 1,
    available_kids: 0,
    available_baby: 0,
    array_mens: [],
    array_womes: [],
    array_kids: [],
    array_baby: [],
    dialog: false,
    phrase: {}
  }),
  mounted: function () {
    this.getWords()
  },
  methods: {
    countUpMens() {
      this.available_mens++;
    },
    countUpWomens() {
      this.available_womens++;
    },
    countUpKids() {
      this.available_kids++;
    },
    countUpBaby() {
      this.available_baby++;
    },
    generateArray() {
      this.array_mens = [...Array(this.available_mens+1).keys()];
      this.array_womens = [...Array(this.available_womens+1).keys()];
      this.array_kids = [...Array(this.available_kids+1).keys()];
      this.array_baby = [...Array(this.available_baby+1).keys()];
    },
    value_fashion_men: function(index, num) {
      this.$store.commit('value_fashion_men', {index, num});
      this.$store.commit('rebalance');
      this.$store.commit('set_progress');
    },
    value_fashion_women: function(index, num) {
      this.$store.commit('value_fashion_women', {index, num});
      this.$store.commit('rebalance');
      this.$store.commit('set_progress');
    },
    value_fashion_kids: function(index, num) {
      this.$store.commit('value_fashion_kids', {index, num});
      this.$store.commit('rebalance');
      this.$store.commit('set_progress');
    },
    value_fashion_baby: function(index, num) {
      this.$store.commit('value_fashion_baby', {index, num});
      this.$store.commit('rebalance');
      this.$store.commit('set_progress');
    },
    getWords: function () {
      this.phrase = words
    }
  },
  computed: {
    set_value: function(){
      return [this.$store.state.breakdown.fashion_mens, this.$store.state.breakdown.fashion_womens, this.$store.state.breakdown.fashion_kids, this.$store.state.breakdown.fashion_baby];
    }
  },
  created() {
    if(this.$store.state.login_response.persona.is_married == 1) {
      this.countUpMens();
      this.countUpWomens();
    }
    
    if('child1_age' in this.$store.state.login_response.persona) {
      if(this.$store.state.login_response.persona.child1_age <= 2) {
        this.countUpBaby();
      }
      else if(this.$store.state.login_response.persona.child1_age >= 3) {
        this.countUpKids();
        if(this.$store.state.login_response.persona.child1_age >= 13) {
          this.countUpMens();
          this.countUpWomens();
        }
      }
    }

    if('child2_age' in this.$store.state.login_response.persona) {
      if(this.$store.state.login_response.persona.child2_age <= 2) {
        this.countUpBaby();
      }
      else if(this.$store.state.login_response.persona.child2_age >= 3) {
        this.countUpKids();
        if(this.$store.state.login_response.persona.child2_age >= 13) {
          this.countUpMens();
          this.countUpWomens();
        }
      }
    }
    this.generateArray();
    this.$store.commit('initialize_fashion_men', this.$store.state.login_response.fashion_men);
    if(this.$store.state.login_response.fashion_women !== null) this.$store.commit('initialize_fashion_women', this.$store.state.login_response.fashion_women);
    if(this.$store.state.login_response.fashion_kids !== null) this.$store.commit('initialize_fashion_kids', this.$store.state.login_response.fashion_kids);
    if(this.$store.state.login_response.fashion_baby !== null) this.$store.commit('initialize_fashion_baby', this.$store.state.login_response.fashion_baby);
  }
})
</script>
