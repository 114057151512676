<template>
  <v-row>
    <v-col class="d-inline-flex align-center" cols="12">
      <v-dialog v-model="dialog" max-width="800" scrollable persistent transition="dialog-bottom-transition">
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on">
            <v-icon id="electricity">{{ icon }}</v-icon>{{ phrase.title_electricity }}
          </v-btn>
        </template>

        <v-card height=400>
          <v-card-title class="text-h5 grey lighten-2">{{ phrase.title_electricity }}</v-card-title>
          <v-card-text>
            <div class="my-5 text--primary">{{ phrase.help_electricity }}</div>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="dialog = false">
              {{ phrase.dialog_close }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-select disabled class="d-inline-flex align-center ml-4" :items=dummy item-text="people" item-value="cost" label="住宅によって決定されます"></v-select>
    </v-col>
    <v-col>
      <v-card min-height="100%" min-width="80%">
        <v-card-title>{{ phrase.cost_electricity }}</v-card-title>
        <v-layout justify-center>
          <v-img max-height="200" max-width="200" v-bind:src="require('@/assets/electricity/electricity.jpg')"></v-img>
        </v-layout>
        <v-card-title>{{ this.$store.state.breakdown.electricity.toLocaleString() }}円</v-card-title>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import words from '../assets/words.json'

export default({
  data: () => ({
    icon: 'mdi-lightning-bolt',
    dummy: [],
    dialog: false,
    phrase: {}
  }),
  mounted: function () {
    this.getWords()
  },
  methods: {
    getWords: function () {
      this.phrase = words
    }
  }
})
</script>
