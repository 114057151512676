<template>
  <v-app>
    <v-container class="overflow-y-auto">
      <v-row justify="center" align-content="center">
        <v-col class="ma-2" cols="6" sm="6" md="4" lg="4" width="400" max-width="400">
          <v-btn rounded @click="debug" v-if="this.$store.state.is_debug == true">1. Input Persona 69/6141</v-btn>
          <v-select :items="targets" label="2. Select Target" @change="set_target" clearable v-if="this.$store.state.is_debug == true"></v-select>
        </v-col>
      </v-row>
      <v-row justify="center" align-content="center">
        <v-card class="ma-2" cols="6" sm="6" md="4" lg="4" width="400" max-width="400">
          <v-card-title class="justify-center">
            {{ phrase.title_finance_park }}
          </v-card-title>
          <v-layout justify-center>
            <v-img max-height="150" max-width="150" contain v-bind:src="require('@/assets/ja.jpg')"></v-img>
            <v-img max-height="150" max-width="150" contain v-bind:src="require('@/assets/shinagawa.jpg')" v-if="this.$store.state.target == 'shinagawa'"></v-img>
            <v-img max-height="150" max-width="150" contain v-bind:src="require('@/assets/gibraltar_sq.jpg')" v-if="this.$store.state.target == 'gibraltar'"></v-img>
          </v-layout>
          <v-card-subtitle></v-card-subtitle>
          <form>
            <v-col>
              <v-text-field
                v-model="account"
                label="アカウント番号"
                required
                type=number
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="pin"
                label="ピン番号"
                required
                type=number
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="name"
                label="あなたの名前"
                required
              ></v-text-field>
            </v-col>
            <v-col>
              <v-select :items="spouseAges" v-model="spouseAge" label="配偶者の年齢 ※いない場合は選択しない" clearable></v-select>
            </v-col>
            <v-col>
              <v-select small-chips multiple :items="stock_list" v-model="stock" label="保有したい株式銘柄を3つ選択してください"></v-select>
            </v-col>
            <v-col>
              <v-text-field
                v-model="password"
                label="パスワード"
                required
              ></v-text-field>
            </v-col>
            <v-col>
              <v-btn class="mr-4" rounded :disabled="dialog" @click="login">{{ phrase.button_login }}</v-btn>
            </v-col>
          </form>
        </v-card>
        <v-dialog v-model="dialog" persistent width="300">
          <v-card cols="3" sm="3" md="2" lg="2" width="300" max-width="300">
            <v-btn icon :disabled="communication" @click="close_dialog">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-card-text>
              <v-progress-linear :active="communication" :indeterminate="communication" color="orange" class="mb-0"></v-progress-linear>
              {{ this.error_message }}
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-row>
      <br><br>
    </v-container>
    <v-footer app height=40>
      <v-row align-content="center">
        <v-col align="center">
          ©Junior Achievement Japan All rights reserved.
        </v-col>
      </v-row>
    </v-footer>
  </v-app>
</template>

<script>
import words from '../assets/words.json'
import axios from 'axios';

// import { mapState, mapActions } from 'vuex'

export default ({
  data: () => ({
    targets: ['ja', 'shinagawa', 'gibraltar'],
    password: '',
    account: null,
    pin: null,
    name: null,
    spouseAge: null,
    spouseAges: [25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45],
    stock: null,
    stock_list: ['キッコーマン', 'ゼビオHD', '三菱商事', 'ローソン', '大和証券グループ', 'TOTO', '出光興産', 'クリナップ', 'セブンイレブン', 'ハニーズHD', 'フォーシーズHD'],
    dialog: false,
    communication: false,
    error_message: '',
    phrase: {}
  }),
  methods: {
    login(){
      if (this.dialog) return;
      this.dialog = true;
      this.communication = true;
      this.doSomething()
        .then(() => {
          axios.get('https://b0leds86fc.execute-api.ap-northeast-1.amazonaws.com/login', 
          {
            params: 
              {
                password: this.password,
                account: this.account, 
                pin: this.pin, 
                name: this.name, 
                spouse_age: this.spouseAge,
                stock: JSON.stringify(this.stock),
                target: this.$store.state.target,
                env: this.$store.state.env
              }
          }).then(response => {
            // handle success(axiosの処理が成功した場合に処理させたいことを記述)
            console.log('response', response);
            console.log('response.data', response.data);
            console.log('response.status', response.status);
            console.log('response.statusText', response.statusText);
            console.log('response.headers', response.headers);
            console.log('response.config', response.config);
            this.set_login_response(response.data);
            this.set_balance(this.$store.state.login_response.persona.net_monthly_income + this.$store.state.login_response.persona.lucky_card);
            this.$router.push({ name:'Home'});
            this.dialog = false;
          }).catch(error => {
            // handle error(axiosの処理にエラーが発生した場合に処理させたいことを記述)
            if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              console.log('error.response.data', error.response.data);
              console.log('error.response.status', error.response.status);
              console.log('error.response.headers', error.response.headers);
              console.log('error.response.data.message', error.response.data.message);
              this.error_message = error.response.data.message;
            } else if (error.request) {
              // The request was made but no response was received
              // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
              // http.ClientRequest in node.js
              console.log('error.request', error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log('Error(error.message)', error.message);
            }
            console.log('error.config', error.config);
          }).finally(() => {
            // always executed(axiosの処理結果によらずいつも実行させたい処理を記述)
            this.communication = false;
          });
        });
    },
    close_dialog() {
      this.dialog = false;
      this.error_message = '';
    },
    doSomething() {
      return new Promise((resolve) => {
        setTimeout(() => {
          console.log(`Submitted on ${new Date()}`);
          resolve();
        }, 1000);
      });
    },
    // ...mapActions (['getPosts']),
    set_login_response: function(value) {
      this.$store.commit('set_login_response', value);
    },
    set_balance: function(value) {
      this.$store.commit('set_balance', value);
    },
    debug() {
      this.account = 69;
      this.pin = 6141;
      this.name = 'テストユーザー';
      this.spouseAge = 30;
      this.stock = ['キッコーマン', 'ゼビオHD', '三菱商事'];
    },
    value_stock() {
      console.log('this.stock', this.stock);
    },
    set_target(target) {
      this.$store.commit('set_target', target);
    },
    set_env(env) {
      this.$store.commit('set_env', env);
    },
    set_is_debug(is_debug) {
      this.$store.commit('set_is_debug', is_debug);
    },
    getWords: function () {
      this.phrase = words
    }
  },
  // computed: {
  //   ...mapState(['login_response'])
  // },
  mounted() {
    this.getWords()
    this.$store.dispatch('getPosts');

    window.history.pushState(null, null, null);
    window.addEventListener("popstate", function() {
      window.history.pushState(null, null, null);
      return;
    });
  },
  created() {
    if(document.domain.split('.')[0] == 'ja-japan-fp'){
      this.set_target('ja');
      this.set_env('PRD');
    }else
      if(document.domain.split('.')[0] == 'shinagawa'){
        this.set_target('shinagawa');
        this.set_env('PRD');
      }else
        if(document.domain.split('.')[0] == 'gibraltar'){
          this.set_target('gibraltar');
          this.set_env('PRD');
        }else{
          this.set_is_debug(true);
          this.set_env('DEV');
          console.log('Target None. Set Debug Mode.');
        }
  }
})
</script>