<template>
  <v-container fluid class="grey lighten-2">
    <v-row>
      <v-col class="mt-2" cols="12">
        <v-dialog v-model="dialog" max-width="800" scrollable persistent transition="dialog-bottom-transition">
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on">
              <v-icon id="lesson">{{ icon }}</v-icon>{{ phrase.title_lesson }}
            </v-btn>
          </template>

          <v-card height=400>
            <v-card-title class="text-h5 grey lighten-2">{{ phrase.title_lesson }}</v-card-title>
            <v-card-text>
              <div class="my-5 text--primary">{{ phrase.help_lesson }}</div>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="dialog = false">
                {{ phrase.dialog_close }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
      <v-col>
        <v-sheet color="grey lighten-2" elevation="10">
          <v-col>大人</v-col>
          <v-col>
            <v-row>
              <v-col class="mt-2" cols="6" sm="6" md="3" lg="3" v-for="(lesson, index) in this.$store.state.login_response.lesson_adult" :key="index">
                <v-card>
                  <v-card-title>{{ lesson.type }}</v-card-title>
                  <v-col align="center">
                    <v-img max-height="200" max-width="200" v-bind:src="require('@/assets/lesson/'+lesson.img+'.jpg')"></v-img>
                  </v-col>
                  <v-card-text>
                    <v-simple-table>
                      <thead></thead>
                      <tbody>
                        <tr>
                          <td>内容</td>
                          <td>{{ lesson.content }}</td>
                        </tr>
                        <tr>
                          <td>備考</td>
                          <td>{{ lesson.note }}</td>
                        </tr>
                        <tr>
                          <td>1人当たり金額</td>
                          <td>{{ lesson.cost.toLocaleString() }}円</td>
                        </tr>
                      </tbody>
                    </v-simple-table>
                    <br>
                    <v-select :items=array_adult return-object :label="$store.state.select.lesson.adult[index]" dense @change="value_lesson_adult(index, $event)"></v-select>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
          <v-col>小計{{ this.$store.state.breakdown.lesson_adult.reduce((sum, i) => { return sum + i.order; }, 0).toLocaleString() }}円</v-col>
          <div v-if="this.$store.state.login_response.lesson_child">
            <v-col>子供</v-col>
            <v-col>
              <v-row>
                <v-col class="mt-2" cols="6" sm="6" md="3" lg="3" v-for="(lesson, index) in this.$store.state.login_response.lesson_child" :key="index">
                  <v-card>
                    <v-card-title>{{ lesson.type }}</v-card-title>
                    <v-col align="center">
                      <v-img max-height="200" max-width="200" v-bind:src="require('@/assets/lesson/'+lesson.img+'.jpg')"></v-img>
                    </v-col>
                    <v-card-text>
                      <v-simple-table>
                        <thead></thead>
                        <tbody>
                          <tr>
                            <td>内容</td>
                            <td>{{ lesson.content }}</td>
                          </tr>
                          <tr>
                            <td>備考</td>
                            <td>{{ lesson.note }}</td>
                          </tr>
                          <tr>
                            <td>1人当たり金額</td>
                            <td>{{ lesson.cost.toLocaleString() }}円</td>
                          </tr>
                        </tbody>
                      </v-simple-table>
                      <br>
                      <v-select :items=array_child return-object :label="$store.state.select.lesson.child[index]" dense @change="value_lesson_child(index, $event)"></v-select>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
            <v-col>小計{{ this.$store.state.breakdown.lesson_child.reduce((sum, i) => { return sum + i.order; }, 0).toLocaleString() }}円</v-col>
          </div>
          <v-col class="mt-2" cols="6" sm="6" md="3" lg="3">
            <v-card min-height="100%" min-width="80%">
              <v-card-title>合計支出金額</v-card-title>
              <v-card-title>{{ (
                this.$store.state.breakdown.lesson_adult.reduce((sum, i) => { return sum + i.order; }, 0) +
                this.$store.state.breakdown.lesson_child.reduce((sum, i) => { return sum + i.order; }, 0) 
              ).toLocaleString() }}円
              </v-card-title>
            </v-card>
          </v-col>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import words from '../assets/words.json'

export default({
  data: () => ({
    icon: 'mdi-school',
    available_adult: 1,
    available_child: 0,
    array_adult: [],
    array_child: [],
    dialog: false,
    phrase: {}
  }),
  mounted: function () {
    this.getWords()
  },
  methods: {
    generateArray() {
      this.array_adult = [...Array(this.available_adult+1).keys()];
      this.array_child = [...Array(this.available_child+1).keys()];
    },
    value_lesson_adult: function(index, num) {
      this.$store.commit('value_lesson_adult', {index, num});
      this.$store.commit('rebalance');
      this.$store.commit('set_progress');
    },
    value_lesson_child: function(index, num) {
      this.$store.commit('value_lesson_child', {index, num});
      this.$store.commit('rebalance');
      this.$store.commit('set_progress');
    },
    getWords: function () {
      this.phrase = words
    }
  },
  created() {
    if(this.$store.state.login_response.persona.is_married == 1) {
      this.available_adult++;
    }
    if('child1_age' in this.$store.state.login_response.persona) {
      this.available_child++;
    }
    if('child2_age' in this.$store.state.login_response.persona) {
      this.available_child++;
    }
    this.generateArray();
    this.$store.commit('initialize_lesson_adult', this.$store.state.login_response.lesson_adult);
    if(this.$store.state.login_response.lesson_child !== null) this.$store.commit('initialize_lesson_child', this.$store.state.login_response.lesson_child);
  }
})
</script>
