<template>
  <v-container fluid class="grey lighten-2">
    <v-row>
      <v-col class="mt-2" cols="12">
        <v-dialog v-model="dialog" max-width="800" scrollable persistent transition="dialog-bottom-transition">
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on">
              <v-icon id="saving">{{ icon }}</v-icon>{{ phrase.title_saving }}
            </v-btn>
          </template>

          <v-card height=400>
            <v-card-title class="text-h5 grey lighten-2">{{ phrase.title_saving }}</v-card-title>
            <v-card-text>
              <div class="my-5 text--primary">{{ phrase.help_saving }}</div>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="dialog = false">
                {{ phrase.dialog_close }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
      <v-col class="mt-2" cols="6" sm="6" md="3" lg="3">
        <v-card>
          <v-card-title>貯金額</v-card-title>
          <v-layout justify-center>
            <v-img max-height="200" max-width="200" v-bind:src="require('@/assets/saving/saving.jpg')"></v-img>
          </v-layout>
          <v-col>
            <v-select :items="selects" v-model="saving" :label="this.$store.state.select.saving" @change="value_saving"></v-select>
            <!-- <v-text-field type="number" label="貯金額を入力してください" suffix=",000円" v-model.number="saving" @change="value_saving"></v-text-field> -->
          </v-col>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import words from '../assets/words.json'

export default({
  data: () => ({
    icon: 'mdi-cash-fast',
    saving: 0,
    selects: [],
    dialog: false,
    phrase: {}
  }),
  mounted: function () {
    this.getWords()
  },
  methods: {
    value_saving: function(value) {
      this.$store.commit('value_saving', value);
      this.$store.commit('rebalance');
      this.$store.commit('set_progress');
    },
    getWords: function () {
      this.phrase = words
    }
  },
  computed: {
    set_value: function(){
      return this.$store.state.breakdown.saving;
    }
  },
  created() {
    for(var i = 0; i <= 200; i++){
      this.selects[i] = i * 1000;
    }
    this.saving = this.$store.state.breakdown.saving;
  }
})
</script>
