<template>
  <v-container fluid class="grey lighten-2">
    <v-row>
      <v-col class="mt-2" cols="12">
        <v-dialog v-model="dialog" max-width="800" scrollable persistent transition="dialog-bottom-transition">
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on">
              <v-icon id="smartphone">{{ icon }}</v-icon>{{ phrase.title_smartphone }}
            </v-btn>
          </template>

          <v-card height=400>
            <v-card-title class="text-h5 grey lighten-2">{{ phrase.title_smartphone }}</v-card-title>
            <v-card-text>
              <div class="my-5 text--primary">{{ phrase.help_smartphone }}</div>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="dialog = false">
                {{ phrase.dialog_close }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
      <v-col>
        <v-card class="mt-2" cols="6" sm="6" md="4" lg="4" max-width="520">
          <v-card-title>契約内容</v-card-title>
          <v-layout justify-center>
            <v-img max-height="200" max-width="200" v-bind:src="require('@/assets/smartphone/smartphone.jpg')"></v-img>
          </v-layout>
          <v-card-text>
            <v-simple-table>
              <thead></thead>
              <tbody>
                <tr>
                  <td>契約人数(大人)</td>
                  <td>{{ num }}名</td>
                </tr>
                <tr>
                  <td>通信事業者/プラン</td>
                  <td>
                    <v-select :items=sortedSmartphone item-text="plan" item-value="cost" return-object :label="this.$store.state.select.smartphone" @change="value_smartphone"></v-select>
                  </td>
                </tr>
                <tr>
                  <td>支払い金額</td>
                  <td>{{ this.$store.state.breakdown.smartphone.toLocaleString() }}円</td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import words from '../assets/words.json'

export default({
  data: () => ({
    icon: 'mdi-cellphone',
    num: 1,
    dialog: false,
    phrase: {}
  }),
  mounted: function () {
    this.getWords()
  },
  methods: {
    value_smartphone: function(value) {
      this.$store.commit('value_smartphone', value);
      this.$store.commit('rebalance');
      this.$store.commit('set_progress');
    },
    sortSmartphone() {
      return this.$store.state.login_response.smartphone.sort((a, b) => {return a.id - b.id;});
    },
    getWords: function () {
      this.phrase = words
    }
  },
  created() {
    if(this.$store.state.login_response.persona.is_married == 1) {
      this.num++;
    }
  },
  computed: {
    sortedSmartphone() {
      this.sortSmartphone();
      return this.$store.state.login_response.smartphone;
    }
  }
})
</script>
