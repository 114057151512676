<template>
  <v-container fluid class="grey lighten-2">
    <v-row>
      <v-col class="mt-2" cols="12">
        <v-dialog v-model="dialog" max-width="800" scrollable persistent transition="dialog-bottom-transition">
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on">
              <v-icon id="trip">{{ icon }}</v-icon>{{ phrase.title_trip }}
            </v-btn>
          </template>

          <v-card height=400>
            <v-card-title class="text-h5 grey lighten-2">{{ phrase.title_trip }}</v-card-title>
            <v-card-text>
              <div class="my-5 text--primary">{{ phrase.help_trip }}</div>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="dialog = false">
                {{ phrase.dialog_close }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
      <v-col>
        <v-row>
          <v-col class="mt-2" cols="6" sm="6" md="4" lg="4" v-for="(trip, index) in this.$store.state.login_response.trip" :key="index">
            <v-card>
              <v-card-title>{{ trip.place }}</v-card-title>
              <v-col align="center">
                <v-img max-height="200" max-width="200" v-bind:src="require('@/assets/trip/'+trip.img+'.jpg')"></v-img>
              </v-col>
              <v-card-text>
                <v-simple-table>
                  <thead></thead>
                  <tbody>
                    <tr>
                      <td>対象</td>
                      <td>{{ trip.course }}</td>
                    </tr>
                    <tr>
                      <td>滞在期間</td>
                      <td>{{ trip.nights }}泊/{{ trip.days }}日</td>
                    </tr>
                    <tr>
                      <td>クラス/ホテル評価</td>
                      <td>{{ trip.class }}/{{ trip.rating }}</td>
                    </tr>
                    <tr>
                      <td>部屋</td>
                      <td>{{ trip.room }}</td>
                    </tr>
                    <tr>
                      <td>備考</td>
                      <td>{{ trip.note }}</td>
                    </tr>
                    <tr>
                      <td>総額/大人1名</td>
                      <td>{{ trip.total.toLocaleString() }}円</td>
                    </tr>
                    <tr>
                      <td>今月支払額/大人1名</td>
                      <td>{{ trip.cost_adult.toLocaleString() }}円</td>
                    </tr>
                    <tr>
                      <td>今月支払額/子ども1名</td>
                      <td>{{ trip.cost_child.toLocaleString() }}円</td>
                    </tr>
                  </tbody>
                </v-simple-table>
                <br>
                <v-row>
                  <v-col>
                    <v-select :items=array_adult return-object :label="$store.state.select.trip.adult[index]" dense @change="value_trip_adult(index, $event)"></v-select>
                  </v-col>
                  <v-col>
                    <v-select :items=array_child return-object :label="$store.state.select.trip.child[index]" dense @change="value_trip_child(index, $event)"></v-select>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col class="mt-2" cols="6" sm="6" md="4" lg="4">
            <v-card min-height="100%" min-width="80%">
              <v-card-title>合計支出金額</v-card-title>
              <v-card-title>{{ (
                this.$store.state.breakdown.trip.reduce((sum, i) => { return sum + i.order_adult; }, 0) +
                this.$store.state.breakdown.trip.reduce((sum, i) => { return sum + i.order_child; }, 0) 
              ).toLocaleString() }}円</v-card-title>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import words from '../assets/words.json'

export default({
  data: () => ({
    icon: 'mdi-airplane',
    available_adult: 5,
    available_child: 0,
    array_adult: [],
    array_child: [],
    dialog: false,
    phrase: {}
  }),
  mounted: function () {
    this.getWords()
  },
  methods: {
    generateArray() {
      this.array_adult = [...Array(this.available_adult+1).keys()];
      this.array_child = [...Array(this.available_child+1).keys()];
    },
    value_trip_adult: function(index, num) {
      this.$store.commit('value_trip_adult', {index, num});
      this.$store.commit('rebalance');
      this.$store.commit('set_progress');
    },
    value_trip_child: function(index, num) {
      this.$store.commit('value_trip_child', {index, num});
      this.$store.commit('rebalance');
      this.$store.commit('set_progress');
    },
    getWords: function () {
      this.phrase = words
    }
  },
  created() {
    if(this.$store.state.login_response.persona.is_married == 1) {
      this.available_adult = 2;
    }
    if('child1_age' in this.$store.state.login_response.persona) {
      if(this.$store.state.login_response.persona.child1_age >= 3) {
        this.available_child++;
      }
    }
    if('child2_age' in this.$store.state.login_response.persona) {
      if(this.$store.state.login_response.persona.child2_age >= 3) {
        this.available_child++;
      }
    }
    this.generateArray();
    this.$store.commit('initialize_trip', this.$store.state.login_response.trip);
  }
})
</script>
