<template>
  <v-row>
    <v-col class="d-inline-flex align-center" cols="12">
      <v-dialog v-model="dialog" max-width="800" scrollable persistent transition="dialog-bottom-transition">
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on">
            <v-icon id="water">{{ icon }}</v-icon>{{ phrase.title_water }}
          </v-btn>
        </template>

        <v-card height=400>
          <v-card-title class="text-h5 grey lighten-2">{{ phrase.title_water }}</v-card-title>
          <v-card-text>
            <div class="my-5 text--primary">{{ phrase.help_water }}</div>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="dialog = false">
              {{ phrase.dialog_close }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-select class="d-inline-flex align-center ml-4" :items=sortedWater item-text="select" item-value="cost" :label="this.$store.state.select.water" return-object @change="value_water"></v-select>
    </v-col>
    <v-col>
      <v-card min-height="100%" min-width="80%">
        <v-card-title>{{ phrase.cost_water }}</v-card-title>
        <v-layout justify-center>
          <v-img max-height="200" max-width="200" v-bind:src="require('@/assets/water/water.jpg')"></v-img>
        </v-layout>
        <v-card-title>{{ this.$store.state.breakdown.water.toLocaleString() }}円</v-card-title>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import words from '../assets/words.json'

export default({
  data: () => ({
    icon: 'mdi-water',
    dialog: false,
    phrase: {}
  }),
  mounted: function () {
    this.getWords()
  },
  methods: {
    sortWater() {
      return this.$store.state.login_response.water.sort((a, b) => {return a.people - b.people;});
    },
    value_water: function(select) {
      console.log('value', select);
      this.$store.commit('value_water', select);
      this.$store.commit('rebalance');
      this.$store.commit('set_progress');
    },
    getWords: function () {
      this.phrase = words
    }
  },
  computed: {
    sortedWater() {
      return this.sortWater();
    }
  }
})
</script>
