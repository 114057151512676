<template>
  <v-container fluid class="grey lighten-2">
    <v-row>
      <v-col class="mt-2" cols="12">
        <v-dialog v-model="dialog" max-width="800" scrollable persistent transition="dialog-bottom-transition">
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on">
              <v-icon id="stock2">{{ icon }}</v-icon>{{ phrase.title_stock2 }}
            </v-btn>
          </template>

          <v-card height=400>
            <v-card-title class="text-h5 grey lighten-2">{{ phrase.title_stock2 }}</v-card-title>
            <v-card-text>
              <div class="my-5 text--primary">{{ phrase.help_stock2 }}</div>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="dialog = false">
                {{ phrase.dialog_close }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
      <v-col class="my-n4" cols="12">
        <v-img max-height="40" max-width="150" contain v-bind:src="require('@/assets/daiwashoken.jpg')" v-if="this.$store.state.login_response.queryStringParameters.target == 'shinagawa'"></v-img>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card class="mt-2" cols="6" sm="6" md="4" lg="4" max-width="520">
          <v-card-title>株価の中間値をお知らせします</v-card-title>
          <v-card-text>
            <v-simple-table dense fixed-header>
              <thead>
                <th>銘柄</th>
                <th>持株数</th>
                <th>中間値</th>
              </thead>
              <tbody>
                <tr v-for="(item, index) in this.$store.state.login_response.stock" :key="item.brand">
                  <td>{{ item.brand }}</td>
                  <td>{{ item.share }}株</td>
                  <td><v-btn depressed @click="click_view_stock(index)">{{item.intermediate_text.toLocaleString()}}円</v-btn></td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import words from '../assets/words.json'

export default({
  data: () => ({
    icon: 'mdi-chart-line',
    dialog: false,
    phrase: {}
  }),
  mounted: function () {
    this.getWords()
  },
  methods: {
    value_stock2: function(value) {
      this.$store.commit('value_stock2', value);
      this.$store.commit('set_progress');
    },
    click_view_stock: function(index){
      this.$store.commit('set_stock_intermediate_text', index);
    },
    getWords: function () {
      this.phrase = words
    }
  }
})
</script>
