<template>
  <v-card min-height="100%" min-width="80%" id="persona">
    <v-card-title>
      {{ phrase.persona_card_title }}
    </v-card-title>
    <v-card-text>
      <v-list>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>{{ phrase.persona_name }}</v-list-item-title>
          </v-list-item-content>
          <v-list-item-content>
            <v-list-item-title>{{ this.$store.state.login_response.queryStringParameters.name }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>{{ phrase.persona_age }}</v-list-item-title>
          </v-list-item-content>
          <v-list-item-content>
            <v-list-item-title>{{ this.$store.state.login_response.persona.age }}歳</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>{{ phrase.persona_family }}</v-list-item-title>
          </v-list-item-content>
          <v-list-item-content>
            <p v-if="this.$store.state.login_response.persona.is_married==1"><v-list-item-title>配偶者{{ this.$store.state.login_response.queryStringParameters.spouse_age }}歳</v-list-item-title></p>
            <p v-if="'child1_age' in this.$store.state.login_response.persona"><v-list-item-title>子{{ this.$store.state.login_response.persona.child1_age }}歳</v-list-item-title></p>
            <p v-if="'child2_age' in this.$store.state.login_response.persona"><v-list-item-title>子{{ this.$store.state.login_response.persona.child2_age }}歳</v-list-item-title></p>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>{{ phrase.persona_nmi }}</v-list-item-title>
          </v-list-item-content>
          <v-list-item-content>
            <v-btn depressed @click="clickViewNMI">{{nmi.text.toLocaleString()}}円</v-btn>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>{{ phrase.persona_lucky_card }}</v-list-item-title>
          </v-list-item-content>
          <v-list-item-content>
            <v-btn depressed @click="clickViewLuckyCard">{{lucky_card.text.toLocaleString()}}円</v-btn>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>{{lucky_card_reason}}</v-list-item-content>
        </v-list-item>
        <v-img max-height="80" max-width="150" contain v-bind:src="require('@/assets/daiwashoken.jpg')" v-if="this.$store.state.login_response.queryStringParameters.target == 'shinagawa'"></v-img>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>{{ phrase.title_stock1 }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      </v-list>
      <v-simple-table dense fixed-header>
        <thead>
          <th>銘柄</th>
          <th>持株数</th>
          <th>始値</th>
        </thead>
        <tbody>
          <tr v-for="item in stock" :key="item.brand">
            <td>{{ item.brand }}</td>
            <td>{{ item.share }}株</td>
            <td>{{ item.opening_price.toLocaleString() }}円</td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script>
import words from '../assets/words.json'

export default {
  data: () => ({
    selectedItem: 1,
    items: [
      { title: '名前' , text: '田中太郎' },
      { title: '年齢' , text: '30歳' },
      { title: '家族' , text: '妻30歳' }
    ],
    nmi: { title: 'NMI' , text: '???' },
    lucky_card: { title: 'ラッキーカード' , text: '???' },
    lucky_card_reason: '',
    stock: [],
    phrase: {}
  }),
  mounted: function () {
    this.getWords()
  },
  methods: {
    clickViewNMI: function(){
      this.nmi.text = this.$store.state.login_response.persona.net_monthly_income;
    },
    clickViewLuckyCard: function(){
      this.lucky_card.text = this.$store.state.login_response.persona.lucky_card;
      if('lucky_card_reason' in this.$store.state.login_response.persona) {
        this.lucky_card_reason = this.$store.state.login_response.persona.lucky_card_reason;
      }
    },
    getWords: function () {
      this.phrase = words
    }
  },
  created(){
    this.stock = this.$store.state.login_response.stock;
  }
}
</script>
