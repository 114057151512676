<template>
    <v-card min-height="100%" min-width="80%">
        <v-card-title>
            {{ phrase.barchart_card_title }}
        </v-card-title>
        <v-card-text class="chart-container"> 
            <Chart :chartData="chartData" :options="options"/>
        </v-card-text>
    </v-card>
</template>

<script>
import words from '../assets/words.json'
import Chart from './barchart.vue'

export default {
    components: {
        Chart
    },
    data() {
        return {
            chartData: {
                labels: ['住宅','食事','ファッション','自動車','スマートフォン','メディア','日用品','医療','生命保険','外食','習い事','防災用品','旅行'],
                datasets: [
                    {
                        label: "下限",
                        data: [18,10,3,12,1,1,1,2,3,1,1,1,2],
                        fill: true,
                        backgroundColor: '#FFAA66',
                    },
                    {
                        label: "上限",
                        data: [6,10,4,7,4,5,2,4,7,9,6,2,6],
                        fill: true,
                        backgroundColor: '#ffcc66',
                    }
                ]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                legend: {
                    display: true
                },
                scales: {
                    'xAxes': [{
                        'stacked': 'true',
                        'scaleLabel': {
                            'display': 'true',
                            'labelString': '費目'
                        }
                    }],
                    'yAxes': [{
                        'stacked': 'true',
                        'scaleLabel': {
                            'display': 'true',
                            'labelString': '%'
                        },
                        'ticks': {
                            'beginAtZero': 'true',
                            'stepSize': 2
                        }
                    }]
                },
                tooltips: {
                    enabled: false
                }
            },
            phrase: {}
        }
    },
    mounted: function () {
        this.getWords()
    },
    methods: {
        getWords: function () {
            this.phrase = words
        }
    }
}
</script>