<template>
  <v-container fluid class="grey lighten-2">
    <v-row>
      <v-col class="mt-2" cols="12">
        <v-dialog v-model="dialog" max-width="800" scrollable persistent transition="dialog-bottom-transition">
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on">
              <v-icon id="daily_necessities">{{ icon }}</v-icon>{{ phrase.title_daily_necessities }}
            </v-btn>
          </template>

          <v-card height=400>
            <v-card-title class="text-h5 grey lighten-2">{{ phrase.title_daily_necessities }}</v-card-title>
            <v-card-text>
              <div class="my-5 text--primary">{{ phrase.help_daily_necessities }}</div>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="dialog = false">
                {{ phrase.dialog_close }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
      <v-col>
        <v-row>
          <v-col class="mt-2" cols="6" sm="6" md="3" lg="3">
            <v-card>
              <v-card-title>デンタルケア</v-card-title>
              <v-layout justify-center>
                <v-img max-height="200" max-width="200" v-bind:src="require('@/assets/daily_necessities/daily_necessities_dental.jpg')"></v-img>
              </v-layout>
              <v-select small-chips multiple class="d-inline-flex align-center mt-2 ml-4" v-model="$store.state.select.daily_necessities.dental" :items=this.$store.state.login_response.daily_necessities_dental item-text="select" return-object label="選択してください" @change="value_daily_necessities_dental"></v-select>
              <v-card-subtitle>小計{{ this.$store.state.breakdown.daily_necessities_dental.toLocaleString() }}円</v-card-subtitle>
            </v-card>
          </v-col>
          <v-col class="mt-2" cols="6" sm="6" md="3" lg="3">
            <v-card>
              <v-card-title>ランドリー</v-card-title>
              <v-layout justify-center>
                <v-img max-height="200" max-width="200" v-bind:src="require('@/assets/daily_necessities/daily_necessities_laundry.jpg')"></v-img>
              </v-layout>
              <v-select small-chips multiple class="d-inline-flex align-center mt-2 ml-4" v-model="$store.state.select.daily_necessities.laundry" :items=this.$store.state.login_response.daily_necessities_laundry item-text="select" return-object label="選択してください" @change="value_daily_necessities_laundry"></v-select>
              <v-card-subtitle>小計{{ this.$store.state.breakdown.daily_necessities_laundry.toLocaleString() }}円</v-card-subtitle>
            </v-card>
          </v-col>
          <v-col class="mt-2" cols="6" sm="6" md="3" lg="3">
            <v-card>
              <v-card-title>サニタリー</v-card-title>
              <v-layout justify-center>
                <v-img max-height="200" max-width="200" v-bind:src="require('@/assets/daily_necessities/daily_necessities_sanitary.jpg')"></v-img>
              </v-layout>
              <v-select small-chips multiple class="d-inline-flex align-center mt-2 ml-4" v-model="$store.state.select.daily_necessities.sanitary" :items=this.$store.state.login_response.daily_necessities_sanitary item-text="select" return-object label="選択してください" @change="value_daily_necessities_sanitary"></v-select>
              <v-card-subtitle>小計{{ this.$store.state.breakdown.daily_necessities_sanitary.toLocaleString() }}円</v-card-subtitle>
            </v-card>
          </v-col>
          <v-col class="mt-2" cols="6" sm="6" md="3" lg="3">
            <v-card>
              <v-card-title>バス</v-card-title>
              <v-layout justify-center>
                <v-img max-height="200" max-width="200" v-bind:src="require('@/assets/daily_necessities/daily_necessities_bath.jpg')"></v-img>
              </v-layout>
              <v-select small-chips multiple class="d-inline-flex align-center mt-2 ml-4" v-model="$store.state.select.daily_necessities.bath" :items=this.$store.state.login_response.daily_necessities_bath item-text="select" return-object label="選択してください" @change="value_daily_necessities_bath"></v-select>
              <v-card-subtitle>小計{{ this.$store.state.breakdown.daily_necessities_bath.toLocaleString() }}円</v-card-subtitle>
            </v-card>
          </v-col>
          <v-col class="mt-2" cols="6" sm="6" md="3" lg="3">
            <v-card>
              <v-card-title>キッチン</v-card-title>
              <v-layout justify-center>
                <v-img max-height="200" max-width="200" v-bind:src="require('@/assets/daily_necessities/daily_necessities_kitchen.jpg')"></v-img>
              </v-layout>
              <v-select small-chips multiple class="d-inline-flex align-center mt-2 ml-4" v-model="$store.state.select.daily_necessities.kitchen" :items=this.$store.state.login_response.daily_necessities_kitchen item-text="select" return-object label="選択してください" @change="value_daily_necessities_kitchen"></v-select>
              <v-card-subtitle>小計{{ this.$store.state.breakdown.daily_necessities_kitchen.toLocaleString() }}円</v-card-subtitle>
            </v-card>
          </v-col>
          <v-col class="mt-2" cols="6" sm="6" md="3" lg="3">
            <v-card>
              <v-card-title>その他</v-card-title>
              <v-layout justify-center>
                <v-img max-height="200" max-width="200" v-bind:src="require('@/assets/daily_necessities/daily_necessities_etc.jpg')"></v-img>
              </v-layout>
              <v-select small-chips multiple class="d-inline-flex align-center mt-2 ml-4" v-model="$store.state.select.daily_necessities.etc" :items=this.$store.state.login_response.daily_necessities_etc item-text="select" return-object label="選択してください" @change="value_daily_necessities_etc"></v-select>
              <v-card-subtitle>小計{{ this.$store.state.breakdown.daily_necessities_etc.toLocaleString() }}円</v-card-subtitle>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="mt-2" cols="6" sm="6" md="3" lg="3">
            <v-card>
              <v-card-title>合計支出金額</v-card-title>
              <v-card-title>{{ (this.$store.state.breakdown.daily_necessities_dental + this.$store.state.breakdown.daily_necessities_laundry + this.$store.state.breakdown.daily_necessities_sanitary + this.$store.state.breakdown.daily_necessities_bath + this.$store.state.breakdown.daily_necessities_kitchen + this.$store.state.breakdown.daily_necessities_etc).toLocaleString() }}円</v-card-title>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import words from '../assets/words.json'

export default({
  data: () => ({
    icon: 'mdi-paper-roll',
    dialog: false,
    phrase: {}
  }),
  mounted: function () {
    this.getWords()
  },
  methods: {
    value_daily_necessities_dental: function(value) {
      this.$store.commit('value_daily_necessities_dental', value);
      this.$store.commit('rebalance');
      this.$store.commit('set_progress');
    },
    value_daily_necessities_laundry: function(value) {
      this.$store.commit('value_daily_necessities_laundry', value);
      this.$store.commit('rebalance');
      this.$store.commit('set_progress');
    },
    value_daily_necessities_sanitary: function(value) {
      this.$store.commit('value_daily_necessities_sanitary', value);
      this.$store.commit('rebalance');
      this.$store.commit('set_progress');
    },
    value_daily_necessities_bath: function(value) {
      this.$store.commit('value_daily_necessities_bath', value);
      this.$store.commit('rebalance');
      this.$store.commit('set_progress');
    },
    value_daily_necessities_kitchen: function(value) {
      this.$store.commit('value_daily_necessities_kitchen', value);
      this.$store.commit('rebalance');
      this.$store.commit('set_progress');
    },
    value_daily_necessities_etc: function(value) {
      this.$store.commit('value_daily_necessities_etc', value);
      this.$store.commit('rebalance');
      this.$store.commit('set_progress');
    },
    getWords: function () {
      this.phrase = words
    }
  }
})
</script>
